<template>
  <v-card-text>
    <p class="mb-0 mt-3">
      กรุณาเพิ่มรายชื่อลูกค้าที่มาจากช่องทางอื่นๆ ที่อยู่นอกเหนือจากระบบสนทนา (Facebook, LINE)
      เพื่อเปิดบิลใหม่
    </p>

<!--    <v-file-input-->
<!--    label="รูปโปรไฟล์"-->
<!--    >-->

<!--    </v-file-input>-->

    <v-file-input v-model="editedItem.member_img_logo"   accept="image/png, image/jpeg, image/bmp" placeholder="แก้ไขไฟล์"
                  @change="changeFile"
                  label="แก้ไขไฟล์"></v-file-input>

    <small
      style="top:-15px"
      class="validate-err"
      v-for="(err,index) in v$.editedItem.member_img_logo.$silentErrors"
      v-if="v$.editedItem.member_img_logo.$error">{{ index > 0 ? ', ' : '' }}
      {{ err.$message }}</small>

    <v-text-field
      v-model="editedItem.member_name"
      label="ชื่อ - สกุล"
      type="text"
      outlined
      dense
      hide-details
      placeholder="ชื่อ - สกุล ลูกค้ารายใหม่"
      class="mt-3"
    ></v-text-field>
    <small
      class="validate-err"
      v-for="(err,index) in v$.editedItem.member_name.$silentErrors"
      v-if="v$.editedItem.member_name.$error">{{ index > 0 ? ', ' : '' }}
      {{ err.$message }}</small>

    <v-text-field
      v-model="editedItem.email"
      label="อีเมล"
      type="email"
      outlined
      dense
      hide-details
      placeholder="อีเมล"
      class="mt-3"
    ></v-text-field>
    <small
      class="validate-err"
      v-for="(err,index) in v$.editedItem.email.$silentErrors"
      v-if="v$.editedItem.email.$error">{{ index > 0 ? ', ' : '' }}
      {{ err.$message }}</small>

    <v-select
    label="ประเภทสมาชิก"
    outlined
    :items="member_types"
    v-model="editedItem.member_type_id"
    text
    dense
    hide-details
    placeholder="เลือกประเภท"
    item-text="member_type_name" item-value="member_type_id"
    class="mt-3">
    </v-select>
    <small
      v-if="v$.editedItem.member_type_id.$error"
      class="validate-err"
      v-for="(err,index) in v$.editedItem.member_type_id.$silentErrors">{{ index > 0 ? ', ' : '' }}
      {{ err.$message }}</small>

    <v-text-field
      v-model="editedItem.password"
      label="รหัสผ่าน"
      type="password"
      outlined
      dense
      hide-details
      placeholder="รหัสผ่าน"
      class="mt-3"
    ></v-text-field>
    <small
      class="validate-err"
      v-for="(err,index) in v$.editedItem.password.$silentErrors"
      v-if="v$.editedItem.password.$error">{{ index > 0 ? ', ' : '' }}
      {{ err.$message }}</small>

    <v-text-field
      v-model="editedItem.member_shop_name"
      label="ชื่อร้าน"
      type="text"
      outlined
      dense
      hide-details
      placeholder="ชื่อร้าน"
      class="mt-3"
    ></v-text-field>
    <small
      class="validate-err"
      v-for="(err,index) in v$.editedItem.member_shop_name.$silentErrors"
      v-if="v$.editedItem.member_shop_name.$error">{{ index > 0 ? ', ' : '' }}
      {{ err.$message }}</small>

    <v-text-field
      v-model="editedItem.member_tele"
      label="เบอร์โทรศัพท์"
      type="text"
      outlined
      dense
      hide-details
      placeholder="เบอร์โทรศัพท์"
      class="mt-3"
    ></v-text-field>
    <small
      class="validate-err"
      v-for="(err,index) in v$.editedItem.member_tele.$silentErrors"
      v-if="v$.editedItem.member_tele.$error">{{ index > 0 ? ', ' : '' }}
      {{ err.$message }}</small>

    <div class="d-flex justify-center mt-5">
      <v-btn
        color="primary"
        @click="store"
        :disabled="disable"
      >
        เพิ่มลูกค้า
      </v-btn>
    </div>
  </v-card-text>
</template>

<script>
import instance_member from '../../../services/member'
import instance_member_type from '../../../services/memberType'
import patternAPI from "@/services/pattern";
import user from "@/services/user";
import {useVuelidate} from "@vuelidate/core";
import {mdiDelete, mdiPencil} from "@mdi/js";
import {required} from "@vuelidate/validators";
import Swal from "sweetalert2";

export default {
  props : [ 'switchOpenUser'],
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data() {
    return {
      name: '',
      disable: false,
      editedIndex: -1,
      member_types : [],
      editedItem: {
        email :'',
        password :'',
        member_shop_name :'',
        member_type_id :'',
        member_name: '',
        member_tele: '',
        member_img_logo: null,
      },
      defaultItem: {
        email :'',
        password :'',
        member_shop_name :'',
        member_type_id :'',
        member_name: '',
        member_tele: '',
        member_img_logo: '',
      },
    }
  },
  validations() {
    var validate = {
      editedItem: {
        member_img_logo: { required },
        email: { required },
        password: { required },
        member_type_id: { required },
        member_shop_name: { required },
        member_name: { required },
        member_tele: { required },
      }
    };
    return validate
  },
  updated() {
    if (this.dataInput !== '') {
      this.disable = false
    }
  },
  methods: {
    getMemberType(){
      instance_member_type.getAll().then(res => {
        this.member_types = res.data.data;
      }).catch(err => {

      })
    },
    changeFile(){
      let $this = this
      // patternAPI
      //   .apiUpdatePattern($this.orderData[index])
      //   .then(res => {
      //     if (res.status === 200) {
      //       this.showOrderMember()
      //     }
      //     // removrToken
      //     user.removrToken(res)
      //   })
      //   .catch(error => {
      //     // this.$swal({
      //     //   text: error.message,
      //     //   timer: 2000,
      //     //   icon: 'error',
      //     //   showConfirmButton: false,
      //     //   button: false
      //     // })
      //   })
    },
    async store() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true;

      instance_member.memberQuickStore(this.editedItem).then(res => {
        this.$store.state.isLoading = false;
        if (res.data.success){
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
          this.switchOpenUser(this.editedItem.member_name);
        }else{
          Swal.fire({
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }
      }).catch(err => {
        this.$store.state.isLoading = false;
        Swal.fire({
          icon: 'error',
          title: err.message,
          showConfirmButton: false,
          timer: 1500
        })

      })

    },
  },
  mounted() {
    this.getMemberType();
  }
}
</script>

<style scoped>
p {
  line-height: 1.5rem;
}
.validate-err {
  color: red;
  margin-top: 300px;
  position: relative;
  top: 5px;
}
</style>
