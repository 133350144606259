<template>
  <v-card-text>
    <v-text-field
      v-model="keySearch"
      label="ชื่อ - สกุล"
      type="text"
      outlined
      dense
      hide-details
      placeholder="ชื่อ - สกุล"
      class="mt-5"
      @keyup="search()"
    ></v-text-field>
    <hr class="mt-5" />
    <div class="d-flex justify-space-between mt-3">
      <p class="mb-0">
        รายชื่อลูกค้าล่าสุด
      </p>
<!--      <button>-->
<!--        <span>ดูทั้งหมด</span>-->
<!--      </button>-->
    </div>
    <li
      v-for="item in customerlLtest"
      :key="item.member_id"
      class="mt-3"
    >
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <div style="width: 35px;">
            <img
              style="object-fit: cover;width: 100%"
              @error="replaceUrl"
              :src="item.member_img ? item.member_img : 'https://www.gravatar.com/avatar/a3175a452c7a8fea80c62a198a40f6c9?s=160&d=retro'"
              alt="photoUser"
            />
          </div>
          <div class="warpper_liContent" style="overflow: hidden;width: 400px;">
            <span class="mb-0">{{ item.member_name }}</span>
            <p class="mb-0 font-weight-bold">
            </p>
          </div>
        </div>
        <div class="styleBtn">
          <v-dialog
            transition="dialog-top-transition"
            width="1200"
          >
            <template v-slot:activator="{ on, attrs }">

<!--              v-on="off"-->
              <v-btn
                color="primary"
                v-bind="attrs"
                @click="customerOpen(item)"
              >
                เปิดบิล
              </v-btn>
            </template>
            <template>
              <v-card>
                <CreateBill />
              </v-card>
            </template>
          </v-dialog>
        </div>
      </div>
    </li>
  </v-card-text>
</template>

<script>
import CreateBill from '@/views/pages/bill/Process.vue'
import instance from '../../../axios.service'
import user from '../../../services/user'
import orderAPI from "@/services/order";
import store from "@/store";
export default {
  props : { name },
  components: {
    CreateBill,
  },
  data: () => ({
    customerlLtest: [],
    img: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png',
    // keySearch: 'นาตยา ส้มเทศ',
    keySearch: '',
  }),
  mounted() {
    if (this.name){
      this.keySearch = this.name;
    }
    this.getCustomerlLtest()
  },
  methods: {

    showOrderMember() {
      // this.orderData = [];
      let $this = this


      // this.$router.push({ name : 'pages-order-create' ,params :{ id : store.state.member.member_id }})

      orderAPI
        .showOrder(store.state.member.member_id)
        .then(res => {
          if (res.status === 200) {
            this.$store.state.orderDetil = res.data
            this.$router.push({ name : 'pages-order-create' ,params :{ id : this.$store.state.orderDetil.order_id }})

            // if (res.data.order_detail){
            //
            //   this.$store.state.orderData = res.data.order_detail
            //
            //   if (this.$store.state.orderData && this.$store.state.orderData.length > 0){
            //     for (var i=0;i<this.$store.state.orderData.length;i++){
            //       this.$store.state.orderData[i].changeFile = null
            //     }
            //   }
            //   // $this.orderDetil = res.data
            //   // this.$store.state.orderDetil = $this.orderDetil
            //
            //   if (res.data.order_detail) {
            //     this.$store.state.orderData = res.data.order_detail
            //     // this.$store.commit('setOrderData', res.data.order_detail);
            //   }
            // }else{
            //   this.$store.state.orderData = [];
            // }


          }
          user.removrToken(res)
        })
        .catch(error => {

        })
    },
    replaceUrl(e){
      e.target.src = 'https://www.gravatar.com/avatar/a3175a452c7a8fea80c62a198a40f6c9?s=160&d=retro';
    },
    search() {
      this.getCustomerlLtest()
    },
    getCustomerlLtest() {
      instance
        .get(`${`/customerLatest?search=${this.keySearch}`}`)
        .then(res => {
          if (res.status === 200) {
            this.customerlLtest = res.data.data
          }
          user.removrToken(res)
        })
        .catch(error => {
          console.log(error)
        })
    },
    customerOpen(member) {
      this.$store.state.step = 1
      this.$store.state.orderDetil = {}
      this.$store.state.orderData = []
      this.$store.state.member = member

      this.showOrderMember()

    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
span {
  font-size: 16px;
  font-weight: 700;
  color: #8dbc89;
}

li {
  list-style-type: none;
}
img {
  min-width: 15%;
  width: 15%;
  border-radius: 50%;
  object-fit: cover;
}
.warpper_liContent {
  display: grid;
  align-items: center;
  padding: 6px 0;
  transform: translateY(4px);
  margin-left: 10px;
}
.styleBtn {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  img {
    border-radius: 5px;
    width: 30%;
  }
}
</style>
