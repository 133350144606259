import api from '../axios.service'

export default {
  get(data) {

    return api.get(`pattern/list?search=${data.search}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getPatternProduct(data) {
    return api.get(`/get_pattern_product?member_type_id=${data.member_type_id}&product_type_id=${data.product_type_id}&search=${data.search}`);
  },
  apiAddPattern(data) {
    // let pattern = data
    // let params = new FormData();
    // params.append('member_id', pattern.member_id)
    // params.append('pattern_name', pattern.pattern_name)
    // params.append('pattern_detail', pattern.pattern_detail)
    // params.append('order_id', pattern.order_id)
    // params.append('file', pattern.file)
    // params.append('type_link_add_files','')
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post('/apiAddPattern',params,config,{})
  },
  addPattern(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        if (key == 'list'){
          for(var i=0;i < getFormData[key].length;i++){
            params.append(`lists[${i}][product_id]`, getFormData[key][i].product_id);
            params.append(`lists[${i}][quantity]`, getFormData[key][i].quantity);
            params.append(`lists[${i}][price]`, getFormData[key][i].price);
            params.append(`lists[${i}][product_price_old]`, getFormData[key][i].product_price_old);
            params.append(`lists[${i}][product_costs]`, getFormData[key][i].product_costs);
            // params.append(`lists[${i}][product_costs]`, getFormData[key][i].product_costs);
          }
        }else{
          params.append(key, getFormData[key]);
        }
      }
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post('/addPattern',params,config)
  },
  apiUpdatePattern(data) {
    let pattern = data
    let params = new FormData();
    let nameFile = pattern.changeFile.name;
    params.append('pattern_id', pattern.pattern_id)
    params.append('pattern_name', nameFile)
    params.append('pattern_detail', '')
    params.append('file', pattern.changeFile)
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post('/apiUpdatePattern',params,config)
  },


  update(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        if (key == 'PatternMediaFiles'){
          for(var i=0;i < getFormData[key].length;i++){
            params.append('PatternMediaFiles[]', getFormData[key][i]);
          }
        }else{
          params.append(key, getFormData[key]);
        }
      }
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`pattern/update/${data.pattern_id}`, params, config)
  },
  store(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        if (key == 'PatternMediaFiles'){
          for(var i=0;i < getFormData[key].length;i++){
            params.append('PatternMediaFiles[]', getFormData[key][i]);
          }
        }else{
          params.append(key, getFormData[key]);
        }
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`pattern/store`, params, config)
  },

  destroy(data) {
    let getFormData = data

    let params = new FormData();
    // params.append('_method', 'DELETE');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    return api.delete(`pattern/delete/${data.pattern_id}`, params, {})
  },

  deleteMedia(id) {
    // let getFormData = data

    let params = new FormData();
    // params.append('_method', 'DELETE');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }

    return api.delete(`pattern/pattern_media_remove/${id}`, params, {})
  },

  changeStatus(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    return api.post(`pattern/change_status/${data.id}`, params, {})
  },
  deleteFile() {

    let params = new FormData();

    return api.delete(`report/deleteFilePattern`, params, {})
  },
}
