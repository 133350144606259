<template>
  <div class="content-success">
    <v-icon class="icons" size="80">
<!--      {{ icons.mdiTruckCheckOutline }}-->
    </v-icon>
    <p class="text-center">การทำรายการสำเร็จ</p>
    <StepOrderSuccess

      :transportTypeItems="transportTypeItems"
      :brandList="brandList"
      :productTypes="productTypes"
      :productList="productList"
    />
  </div>
</template>

<script>
import { mdiTruckCheckOutline } from '@mdi/js'
import StepOrderSuccess from '../../step-transit/order/StepOrder.vue'

export default {
  props : {
    transportTypeItems : { type : Array},
    brandList :  { type : Array},
    productTypes :  { type : Array},
    productList :  { type : Array},
    getOrderDetail :  {},
  },
  components: {
    StepOrderSuccess
  },
  setup() {
    return {
      icons: {
        mdiTruckCheckOutline,
      },
    }
  },
}
</script>

<style scoped>
.content-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
}

.content-success p {
  color: #8dbc8a !important;
  font-size: 20px;
  font-weight: 700;
}

.content-success .icons {
  color: #8dbc8a !important;
}
</style>
