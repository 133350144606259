/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  // eslint-disable-next-line camelcase
  getCheckAmount(product_id) {
    // eslint-disable-next-line camelcase
    return api.get(`/apiCheckProductAmount?product_id=${product_id}`)
  },
}
