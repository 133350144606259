/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  get(data) {
    return api.get(`product_type/list?search=${data.search}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  all() {
    return api.get('product_type/get_all')
  },
  apiProductType() {
    return api.get('/apiProductType')
  },

  update(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    return api.post(`product_type/update/${data.product_type_id}`, params, {})
  },
  store(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`product_type/store`, params, config)
  },

  destroy(data) {
    let getFormData = data

    let params = new FormData();
    // params.append('_method', 'DELETE');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    return api.delete(`product_type/delete/${data.product_type_id}`, params, {})
  },
}
