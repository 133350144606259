<template>
  <v-card flat>
    <v-card-text>
      <div class="test">
        <div class="d-flex justify-space-between mb-5">
          <!-- Button Content 1 -->
          <v-btn color="info" v-bind="attrs" v-on="on" v-if="contentShow" @click="changeContent">
            <span>เลือกอัลบั้ม</span>
            <v-icon class="ms-1">{{ icons.mdiCameraImage }}</v-icon>
          </v-btn>
          <!-- End Button Content 1 -->

          <!-- Button Content 2 -->
          <v-btn color="info" v-bind="attrs" v-on="on" v-if="!contentShow" @click="changeContent">
            <v-icon class="me-1">{{ icons.mdiArrowLeftBold }}</v-icon>
            <span>กลับ</span>
          </v-btn>
          <!-- End Button Content 2 -->
          <div class="search">
            <v-text-field label="ค้นหา" type="text" outlined dense hide-details placeholder="ค้นหา"></v-text-field>
          </div>
        </div>
        <!-- content1 -->
        <div class="d-flex justify-center" v-if="contentShow">
          <li v-for="item in this.$store.state.mockDataAddProduct" :key="item.id" class="mt-2 me-2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <img
                  :src="item.img"
                  v-bind="attrs"
                  v-on="on"
                  alt="image product"
                  class="image_product"
                  :class="{ style_active: seletedContent }"
                  @click="activeContent(item.id)"
                />
              </template>
              <span>{{ item.nameProduct }}</span>
            </v-tooltip>
            <span v-if="seletedContent" class="textContent">({{ item.nameProduct }})</span>
          </li>
        </div>
        <p class="text-center mb-0 mt-2 seemore" v-if="contentShow">ดูเพิ่มเติม</p>
        <!-- end content1 -->

        <div v-if="!contentShow">
          <div class="d-flex content2" @click="changeContent">
            <img src="https://cdn.pixabay.com/photo/2017/02/16/13/42/box-2071537_1280.png" alt="mockData" />
            <div class="mx-2 displayContent">
              <span class="textHeader">รูปล่าสุด</span>
              <span>สินค้า 4 รายการ</span>
            </div>
          </div>
          <div class="d-flex mt-2 content2" @click="changeContent">
            <img
              src="https://cdn.pixabay.com/photo/2022/07/31/20/03/alcoholic-drinks-7356721_1280.png"
              alt="mockData"
            />
            <div class="mx-2 displayContent">
              <span class="textHeader">ทั่วไป</span>
              <span>สินค้า 4 รายการ</span>
            </div>
          </div>
          <div class="d-flex mt-2 content2" @click="changeContent">
            <v-icon size="60" class="icon_style">{{ icons.mdiFolderPlus }}</v-icon>
            <div class="mx-2 d-flex editContent">
              <span class="textHeader">จัดการอัลบั้ม</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <v-btn color="secondary" class="me-2 button_size" @click="gotoPage()">
            <p class="mb-0">นำสินค้าเข้าเพิ่มเติม</p>
          </v-btn>
        </div>
        <hr class="mt-5" />
        <!-- <div class="d-flex justify-end">
          <v-btn color="primary" :disabled="activeButton">
            <p class="mb-0">เพิ่มสินค้า</p>
          </v-btn>
        </div> -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCameraImage, mdiArrowLeftBold, mdiFolderPlus } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiCameraImage,
        mdiArrowLeftBold,
        mdiFolderPlus,
      },
    }
  },
  data() {
    return {
      seletedContent: false,
      activeButton: true,
      contentShow: true,
    }
  },
  methods: {
    activeContent(id) {
      this.seletedContent = !this.seletedContent
      this.$store.state.activeButton = !this.$store.state.activeButton
    },
    gotoPage() {
      const managePage = this.$router.resolve({ path: '/pages/manage-product' })
      window.open(managePage.href)
    },
    changeContent() {
      this.contentShow = !this.contentShow
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
li {
  list-style-type: none;
  position: relative;
}
.content2 {
  cursor: pointer;
}
.content2 img {
  width: 4rem;
  height: 4rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.test {
  min-height: 20rem;
}
.header {
  background-color: red;
}
.search {
  width: 30%;
}
.image_product {
  width: 6.75rem;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
}
.style_active {
  opacity: 0.9;
  border: 2px solid #8dbc8a;
  background: rgba(0, 0, 0, 0.5);
}
.textContent {
  position: absolute;
  left: 10px;
  top: 5px;
  color: white;
}
.button_size {
  width: 100%;
}
.displayContent {
  display: grid;
}
.textHeader {
  font-size: 18px;
  font-weight: 700;
}
.editContent {
  align-items: center;
}
.content2:hover {
  color: #7d7982;
}
.seemore {
  color: #8dbc8a;
  cursor: pointer;
}
.seemore:hover {
  color: #769b73;
}
</style>
