/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  // eslint-disable-next-line camelcase
  productPrice(product_id, member_type_id) {
  
    // eslint-disable-next-line camelcase
    return api.get(`/apiProductPrice?product_id=${product_id}&member_type_id=${member_type_id}`)
  },
}
