/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  update(data,total = null) {
      let getFormData  = data
      let params = new FormData();
      params.append('order_detail_id', getFormData.order_detail_id)
      // params.append('order_detail_id', getFormData.order_detail_id)
      params.append('product_price', getFormData.product_price)
      params.append('product_type_id', getFormData.product_type_id)
      params.append('product_id', getFormData.product_id)
      params.append('phone_brand_id', getFormData.phone_brand_id)

    if (total){
      params.append('order_expense', total)
    }

      // if(getFormData.product_amount){
      //   params.append('product_amount', getFormData.product_amount)
      // }


      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
    return api.post(`/orderDetailUpdate`,params,config,{})
  },
  delete(id){
    return api.delete(`/orderDetail/${id}`)
  }
}
