<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card-text>
      <h2 v-if="$store.state.orderData && $store.state.orderData.length > 0"
          class="mb-5 text-center">
        รายการสินค้า
      </h2>
<!--      <v-alert v-if="$store.state.orderDetil && $store.state.orderDetil.order_inv" dense text-->
<!--               type="warning">-->
<!--        หมายเลขออเดอร์นี้ <strong> {{ $store.state.orderDetil.order_inv }} </strong> จะหมดอายุในเวลา-->
<!--        : <strong>-->
<!--        {{ $store.state.orderDetil.order_date_time }} </strong>-->
<!--      </v-alert>-->
      <v-alert v-if="validate" type="error">
        {{ errorMessage }}
      </v-alert>


      <hr v-if="$store.state.orderData && $store.state.orderData.length > 0" class="mb-5"/>
      <div class="">
        <li v-for="(item, index) in $store.state.orderData" :key="item.id">
          <v-row>
            <v-col md="3" sm="12" cols="12" class="img_proauctRes">
              <center>
                <img
                  v-if="!item.pattern || item.pattern.location_files || item.pattern.example_picture "
                  :src="item.pattern && item.pattern.example_picture ? item.pattern.example_picture : item.pattern.location_files"
                  :alt="item.pattern && item.pattern.pattern_name ? item.pattern.pattern_name : ''"
                  onclick="window.open(this.src)"/>
<!--                <v-file-input v-model="item.changeFile" accept="image/png, image/jpeg, image/bmp"-->
<!--                              placeholder="แก้ไขไฟล์"-->
<!--                              @change="changeFile(index)"-->
<!--                              label="แก้ไขไฟล์"></v-file-input>-->
                <!-- <p><a>แก้ไขไฟล์</a></p> -->
              </center>
            </v-col>
            <v-col md="6" sm="12" cols="12">
              <!-- กรุณาเลือกประเภทสินค้า -->
              <v-select v-model="item.product_type_id" outlined dense hide-details
                        label="กรุณาเลือกประเภทสินค้า"
                        disabled
                        :items="productTypes" item-text="product_type_name"
                        item-value="product_type_id" class="mt-2"
                        :rules="[v => !!v || 'กรุณาเลือกประเภทสินค้า']" required
                        @change="changeProuctType(index)"></v-select>
<!--              :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

              <!-- กรุณาเลือกประเภทสินค้า -->

              <!-- กรุณาเลือกยี่ห้อ -->
              <div v-if="item.product_type_id">
<!--                :disabled="(!item.product_type_id) || !(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)" dense-->
                <v-select v-model="item.phone_brand_id" outlined
                          disabled
                          hide-details label="กรุณาเลือกยี่ห้อ" :items="brandList"
                          item-text="phone_brand_name"
                          :rules="[v => !!v || 'กรุณาเลือกยี่ห้อ']" required
                          item-value="phone_brand_id" class="mt-2"
                          @change="changeModel(index)"></v-select>
              </div>
              <!-- กรุณาเลือกยี่ห้อ -->

              <!-- สินค้าต้องการสั่งผลิต -->
              <div v-if="item.phone_brand_id && item.product && item.product.product">
<!--                :disabled="(!item.phone_brand_id) || !(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

                <v-select v-model="item.product_id"
                          disabled
                          outlined dense
                          hide-details label="สินค้าต้องการสั่งผลิต" :items="productList[index]"
                          item-text="product_name"
                          item-value="product_id"
                          :rules="[v => !!v || 'กรุณาเลือกรุ่น']" required
                          class="mt-2"
                          @change="productPrice(index)"></v-select>
              </div>
              <!-- สินค้าต้องการสั่งผลิต -->

            </v-col>
            <v-col md="3" sm="12" cols="12" v-if="item.product_id">
              <div v-if="item.phone_brand_id && item.product && item.product.product">
<!--                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

                <!-- ราคา -->
                <v-text-field v-model="item.product_price" label="ราคา" type="number" readonly
                              outlined dense
                              disabled
                              hide-details placeholder="ราคา" class="mt-1"></v-text-field>
                <!-- ราคา -->

                <!-- จำนวนคงเหลือ -->
                <div v-if="item.product">
<!--                  :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

                  <v-text-field v-model="item.product.remaining_online" label="จำนวนคงเหลือ"
                                readonly type="number"
                                disabled
                                outlined dense hide-details placeholder="จำนวนคงเหลือ"
                                class="mt-1"></v-text-field>
                </div>
                <!-- จำนวนคงเหลือ   -->

                <div v-if="item.product">
<!--                  :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

                  <v-text-field v-model="item.product_amount" label="จำนวน" type="number"
                                :max="item.product.remaining_online" min="1" outlined dense
                                hide-details placeholder="จำนวน"
                                disabled
                                :rules="[v => !!v || 'กรุณาใสจำนวน']" required class="mt-1"
                                @change="checkAmount(index)">
                  </v-text-field>
                </div>

                <!-- จำนวน -->
<!--                <div  v-if="(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->
<!--                      class="d-flex align-center mt-2">-->

<!--                  <v-icon style="color: red" class="me-1" @click="remove(index)">-->
<!--                    {{ icons.mdiDelete }}-->
<!--                  </v-icon>-->
<!--                </div>-->
                <!-- จำนวน -->
              </div>
            </v-col>
            <hr>
          </v-row>
        </li>
      </div>

<!--      <div class="mt-10"  v-if="(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)">-->
<!--        <hr class="mt-5 mb-5"/>-->
<!--        <div class="text-center wapperContentBottom">-->
<!--          <v-menu top :offset-y="offset">-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <div class="wapperIconContent">-->
<!--                <v-btn title="รูปอัพโหลดไฟล์เข้าระบบ" color="primary" v-bind="attrs" hide-details-->
<!--                       v-on="on">-->
<!--                  <v-icon class="me-1" v-if="icons && icons.mdiPlus">-->
<!--                    {{ icons.mdiPlus }}-->
<!--                  </v-icon>-->
<!--                  เพิ่มอัพโหลดไฟล์-->
<!--                </v-btn>-->
<!--              </div>-->
<!--            </template>-->
<!--            <v-list>-->
<!--              <v-list-item v-for="(item, index) in itemUploadProduct" :key="index">-->
<!--                <v-list-item-title>-->
<!--                  <v-dialog transition="dialog-top-transition" width="1000">-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                      <div>-->
<!--                        <v-btn text v-bind="attrs" class="mt-5 testdialog" :disabled="item.disabled"-->
<!--                               v-on="on">-->
<!--                          <v-icon class="me-1">-->
<!--                            {{ item.icons }}-->
<!--                          </v-icon>-->
<!--                          {{ item.title }}-->
<!--                        </v-btn>-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </v-dialog>-->
<!--                </v-list-item-title>-->
<!--              </v-list-item>-->

<!--              <v-list-item-title>-->
<!--                <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="uploadFile"-->
<!--                       hide-details-->
<!--                       multiple tabindex="-1" @change="uploadFile($event, 1)"/>-->
<!--              </v-list-item-title>-->
<!--            </v-list>-->
<!--          </v-menu>-->
<!--        </div>-->
<!--      </div>-->
      <v-row style="margin-top: 10px">
        <v-col md="3" sm="3" cols="12" class="d-flex align-center">
          <h3>วิธีการส่งสินค้า</h3>
        </v-col>
        <v-col md="3" sm="3" cols="12">
          <v-select v-if="$store.state.orderDetil"
                    v-model="$store.state.orderDetil.transport_type_id" outlined dense hide-details
                    label="กรุณาเลือกวิธีการส่งสินค้า" :items="transportTypeItems"
                    item-text="transport_type_name"
                    disabled
                    :rules="[v => !!v || 'กรุณาใสจำนวน']" required item-value="transport_type_id"
                    class="mt-2"
                    @change="settransport()"></v-select>
          <!--                    :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

        </v-col>
        <!-- วิธีการส่งสินค้า -->
        <v-col md="3" sm="3" cols="12" class="d-flex align-center">
          <h3>ค่าจัดส่งสินค้า</h3>
        </v-col>
        <v-col md="3" sm="3" cols="12">
          <v-text-field v-model="$store.state.orderDetil.transport_expense_price"
                        label="ค่าจัดส่ง(บาท)" readonly type="number"
                        disabled
                        outlined dense hide-details placeholder="ค่าจัดส่ง"></v-text-field>
<!--          :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

        </v-col>
        <v-col md="3" sm="3" cols="12" class="d-flex align-center">
          <h3>จำนวนสินค้า</h3>
        </v-col>
        <v-col md="3" sm="3" cols="12">
<!--          :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

          <v-text-field v-model="amount" label="จำนวน(ชิ้น)" type="number" outlined readonly dense
                        hide-details
                        disabled
                        placeholder="จำนวน(ชิ้น)"></v-text-field>
        </v-col>
        <v-col md="3" sm="3" cols="12" class="d-flex align-center">
          <h3>ส่วนลด</h3>
        </v-col>
        <v-col md="3" sm="3" cols="12">
<!--          :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

          <v-text-field v-model="$store.state.orderDetil.order_price_discount" label="ส่วนลด" type="number" outlined readonly dense
                        hide-details
                        disabled
                        placeholder="ส่วนลด"></v-text-field>
        </v-col>
        <!-- ยอดสุทธิ -->
        <v-col md="3" sm="3" cols="12" class="d-flex align-center">
          <h3>ยอดรวมสุทธิ</h3>
        </v-col>
        <v-col md="3" sm="3" cols="12">
<!--          :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->

          <v-text-field v-model="$store.state.orderDetil.order_expense" label="ราคา (บาท)" type="number" outlined readonly dense
                        hide-details
                        disabled
                        placeholder="ราคา (บาท)"></v-text-field>
        </v-col>
      </v-row>

    </v-card-text>
    <v-card-actions class="justify-end">
<!--      <v-btn-->
<!--        v-if="($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id"-->
<!--        color="primary" @click="validateForm()">-->
<!--        บันทึกข้อมูล-->
<!--      </v-btn>-->
    </v-card-actions>
  </v-form>
</template>

<script>
import {
  mdiPlus,
  mdiPlusCircle,
  mdiMinusCircle,
  mdiCloseCircle,
  mdiCloudUpload,
  mdiImageAlbum,
  // mdiArchiveRemove,
  mdiDelete,
} from '@mdi/js'
import AddProduct from '../../AddProduct.vue'
import productType from '../../../../services/productType'
import productAPI from '../../../../services/product'
import user from '../../../../services/user'
import getModelAPI from '../../../../services/model'
import productAmountAPI from '../../../../services/productAmount'
import productPriceAPI from '../../../../services/productPrice'
import transportTypeAPI from '../../../../services/transportType'
import store from '@/store/index.js';
import patternAPI from '../../../../services/pattern'
import orderAPI from '../../../../services/order'
import orderDetailAPI from '../../../../services/orderDetail'
import Swal from "sweetalert2";
import {required} from "@vuelidate/validators";

export default {
  props: {
    Step: {
      type: Number
    },
    transportTypeItems: {type: Array},
    brandList: {type: Array},
    productTypes: {type: Array},
    productList: {type: Array},
    getOrderDetail: {},
  },
  components: {AddProduct, Swal},
  data() {
    return {
      valid: true,
      validate: false,
      errorMessage: '',
      attrs: '',
      product: [],
      itemUploadProduct: [
        {icons: mdiCloudUpload, title: 'อัพโหลดรูป', disabled: false},
      ],
      offset: true,
      selectTransition: '',
      priceAdd: 0,
      discount: 0,
      price: 0,
      productSelect: [],
      transport_type_id: '',
      transportTypePrice: 0,
      bankList: '',
      icons: {
        mdiPlus,
        mdiPlusCircle,
        mdiMinusCircle,
        mdiCloseCircle,
        mdiCloudUpload,
        mdiImageAlbum,
        // mdiArchiveRemove,
        mdiDelete,
      },
    }
  },
  updated() {
    // store.state.orderData = this.orderData;
    // store.state.orderDetil = this.order_detail
    // store.state.validate = this.validate;
    // this.$store.state.orderDetil.order_expense = this.total + this.$store.state.orderDetil.transport_expense_price
  },
  setup() {
    var validate = {
      editedItem: {
        member_img_logo: { required },
        email: { required },
        password: { required },
        member_type_id: { required },
        member_shop_name: { required },
        member_name: { required },
        member_tele: { required },
      }
    };
    return validate
  },
  computed: {

    amount() {
      const total = []
      Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
        if (val.product_amount) {
          total.push(parseInt(val.product_amount))
        } else {
          total.push(0)
        }
      })
      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    total() {
      const total = []
      Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
        if (val.product_price) {
          total.push(parseInt(parseInt(val.product_price) * parseInt(val.product_amount)))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
  },
  watch: {
    // '$store.state.orderData'() {
    //   if (this.$store.state.orderData && this.$store.state.orderData.length > 0) {
    //     var product_type_ids = ''
    //     var phone_brand_ids = ''
    //     var member_id = this.$store.state.member.member_type_id ?? this.$store.state.orderDetil.member;
    //     for (var i = 0; i < this.$store.state.orderData.length; i++) {
    //       if (i > 0) {
    //         phone_brand_ids += ',';
    //         product_type_ids += ',';
    //       }
    //       phone_brand_ids += (this.$store.state.orderData[i].phone_brand_id)
    //       product_type_ids += (this.$store.state.orderData[i].product_type_id)
    //       // phone_brand_ids.push(this.$store.state.orderData[i].phone_brand_id)
    //       // product_type_ids.push(this.$store.state.orderData[i].product_type_id)
    //     }
    //
    //
    //     productAPI
    //       .getProductsByType(product_type_ids, phone_brand_ids, member_id)
    //       .then(res => {
    //         if (res.status === 200) {
    //           if (res.data && res.data.length > 0) {
    //             this.productList = res.data
    //           } else {
    //             this.validate = true
    //             this.errorMessage = 'ไม่พบสินค้า'
    //           }
    //         }
    //         // removrToken
    //         user.removrToken(res)
    //       })
    //       .catch(err => {
    //         Swal.fire({
    //           icon: 'error',
    //           title: err.message,
    //           showConfirmButton: false,
    //           timer: 1500
    //         })
    //       })
    //   }
    // }
  },
  methods: {
    getProductList(){
      if (this.$store.state.orderData && this.$store.state.orderData.length > 0) {
        var product_type_ids = ''
        var phone_brand_ids = ''
        var member_id = this.$store.state.member.member_type_id ?? this.$store.state.orderDetil.member;
        for (var i = 0; i < this.$store.state.orderData.length; i++) {
          if (i > 0) {
            phone_brand_ids += ',';
            product_type_ids += ',';
          }
          phone_brand_ids += (this.$store.state.orderData[i].phone_brand_id)
          product_type_ids += (this.$store.state.orderData[i].product_type_id)
          // phone_brand_ids.push(this.$store.state.orderData[i].phone_brand_id)
          // product_type_ids.push(this.$store.state.orderData[i].product_type_id)
        }


        productAPI
          .getProductsByType(product_type_ids, phone_brand_ids, member_id)
          .then(res => {
            if (res.status === 200) {
              if (res.data && res.data.length > 0) {
                this.productList = res.data
              } else {
                this.validate = true
                this.errorMessage = 'ไม่พบสินค้า'
              }
            }
            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500
            })
          })
      }
    },
    changeFile(index) {
      let $this = this
      patternAPI
        .apiUpdatePattern(this.$store.state.orderData[index])
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })
        })
    },
    orderUpdate() {
      orderAPI
        .orderUpdate(this.$store.state.orderDetil)
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
            Swal.fire({
              icon: 'success',
              title: 'บันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500
            })
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          });
        })
    },
    validateForm() {
      this.$store.state.orderDetil.order_expense = this.total
      this.orderUpdate()
    },
    alertError(message) {
      alert(message)
    },
    settransport() {
      if (this.$store.state.orderDetil.transport_type_id) {
        transportTypeAPI
          .apiTransportExpensePriceRate(this.amount, this.$store.state.orderDetil.transport_type_id)
          .then(res => {
            if (res.status === 200) {
              this.$store.state.orderDetil.transport_expense_price = res.data
              this.validateForm()

            }
            // removrToken
            user.removrToken(res)
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    remove(index) {
      let $this = this
      orderDetailAPI
        .delete($this.$store.state.orderData[index].order_detail_id)
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })
        })
      // this.orderData.splice(index, 1)
    },
    // changeProduct(index, product_id) {
    //   this.orderData[index].productAmount = this.orderData[index].productAmoun
    //   productAmountAPI
    //     .getCheckAmount(product_id)
    //     .then(res => {
    //       if (res.status === 200) {
    //         this.orderData[index].productAmount = res.data
    //       }
    //       // removrToken
    //       user.removrToken(res)
    //     })
    //     .catch(error => {
    //       this.$swal({
    //         text: error.message,
    //         timer: 2000,
    //         icon: 'error',
    //         showConfirmButton: false,
    //         button: false
    //       })
    //     })
    // },
    changeProuctType(index) {
      this.$store.state.orderData[index].productSelect = []
      this.$store.state.orderData[index].phone_brand_id = ''
      this.$store.state.orderData[index].product_id = ''
    },
    changeModel(index) {
      this.$store.state.orderData[index].productSelect = []
      let phone_brand_id = this.$store.state.orderData[index].phone_brand_id
      let product_type_id = this.$store.state.orderData[index].product_type_id
      let $this = this
      let product = this.$store.state.orderData[index].product = {
        product: []
      }
      this.$store.state.orderData[index].product_id = null;
      productAPI
        .getProductByType(product_type_id, phone_brand_id, store.state.member)
        .then(res => {
          if (res.status === 200) {
            if (res.data && res.data.length > 0) {
              this.productList[index] = [];
              this.productList[index] = res.data
              this.productList.splice(index,1, res.data)
            } else {
              this.validate = true
              this.errorMessage = 'ไม่พบสินค้า'
            }
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })
        })
    },
    uploadFile(event, type) {
      this.validateOrder();

      if (!this.validate && type === 1) {
        // const { orderData } = store.state
        // if(files[0]){
        let orderData = this.orderData
        let files = event.target.files
        let data = {
          order_id : this.$store.state.orderDetil.order_id ?? '',
          member_id: this.$store.state.member.member_id ?? this.$store.state.orderDetil.member_id,
          pattern_name: files[0].name,
          pattern_detail: '',
          file: files[0]
        }

        patternAPI
          .apiAddPattern(data)
          .then(res => {
            if (res.status === 200) {
              if (res.data.success){

                Swal.fire({
                  icon: 'success',
                  title: "อัพโหลดสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500
                })
                this.getOrderDetail()
              }else{

                Swal.fire({
                  icon: 'error',
                  title: 'เกิดข้อผิดพลาด !!',
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            }
            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500
            })
          })
        // }


      }
    },
    changeProduct() {
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    checkAmount(index) {
      if (this.$store.state.orderData[index].product_amount) {
        if (this.$store.state.orderData[index].product_type_id && this.$store.state.orderData[index].product_id && this.$store.state.orderData[index].phone_brand_id) {
          if (this.$store.state.orderData[index].product_amount > this.$store.state.orderData[index].product.remaining_online) {
            this.validate = true
            this.errorMessage = 'จำนวนสินค้าไม่เพียงพอ'
            this.$store.state.orderData[index].product_amount = 0;
          } else {
            this.errorMessage = ''
            this.validate = false
          }
        }
        this.settransport();
        if (!this.validate) {
          //updateOrderDetail to save
          this.updateOrderDetail(index)
        }
      }


    },
    validateOrder() {
      for (let i = 0; i < this.$store.state.orderData.length; i++) {
        if (!this.$store.state.orderData[i].product_type_id && !this.$store.state.orderData[i].product_id || !this.$store.state.orderData[i].phone_brand_id) {
          this.validate = true
          this.errorMessage = 'กรุณากรอกข้อมูลให้ครบถ้วนก่อนเพิ่มสินค้า'
          break;
        }
      }

      for (let i = 0; i < this.$store.state.orderData.length; i++) {
        if (this.$store.state.orderData[i].product_amount > this.$store.state.orderData[i].product.remaining_online) {
          this.validate = true
          this.errorMessage = 'จำนวนสินค้าไม่เพียงพอ'
          break;
        }
      }
    },
    setProductPrice(index, product_id) {
      for (let i = 0; i < this.$store.state.orderData[index].product.product.length; i++) {
        if (this.$store.state.orderData[index].product.product[i].product_id === product_id) {
          this.$store.state.orderData[index].product_price = parseInt(this.$store.state.orderData[index].product.product[i].product_costs)
          // console.log(this.orderData[index])
          break;
        }
      }
    },


    checkProductDuplicate(index, product_id) {
      // let errorMessage = 'พบสินค้าซ้ำอยู่ในรายการ';
      // for (let i = 0; i < this.$store.state.orderData.length; i++) {
      //   if (this.$store.state.orderData[i].product_id === product_id) {
      //     this.validate = true
      //     this.errorMessage = errorMessage
      //     this.$store.state.orderData[index].product_id  = null
      //     this.alertError(errorMessage)
      //     break;
      //   }
      // }
    },

    productPrice(index) {
      let product_id = this.$store.state.orderData[index].product_id
      var member_type_id = this.$store.state.member.member_type_id ?? this.$store.state.orderDetil.member.member_type_id;
      // this.checkProductDuplicate(index, product_id)
      this.validate = false;
      if (!this.validate) {
        productPriceAPI
          .productPrice(product_id, member_type_id)
          .then(res => {
            if (res.status === 200) {
              if (res.data) {
                //check rate price
                this.$store.state.orderData[index].product_price = res.data
              } else {
                //check amount price
                this.$store.state.orderData[index].product_price = 0;
                this.setProductPrice(index, product_id)
              }
              if (!this.validate) {
                //updateOrderDetail to save
                this.updateOrderDetail(index)
              }
            }
            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500
            })
          })
      }

    },
    updateOrderDetail(index) {
      orderDetailAPI
        .update(this.$store.state.orderData[index])
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail();
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {

          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })

        })
    },
  },
  created() {

  },
  mounted() {
    let vm = this;
    // console.log('----step----')
    // console.log(this.step,333)
  },
}
</script>

<style scoped>

.v-input__slot {
  width: 50% !important;
}

.v-application--is-ltr .v-input__prepend-outer {
  margin-right: unset !important
}

.v-alert:not(.v-sheet--tile) {
  border-radius: 5px;
  margin-top: 10px;
}

a {
  text-decoration: none;
}

img {
  height: 5.15rem;
  width: 5.235rem;
  border-radius: 10px;
  /*margin-left: 30%;*/
}

li {
  list-style-type: none;
}

hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}

.testdialog {
  margin: 0 !important;
}

.wapper_content1 {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 23rem;
}

.wapperContentBottom {
  position: relative;
}

.wapperIconContent {
  transform: translateY(-100%);
  position: absolute;
  right: 36%;
}

.uploadFile {
  position: absolute;
  top: 15px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}

.img_proauctRes {


}


@media screen and (max-width: 600px) {
  .wapperIconContent {
    right: 25%;
  }
}
</style>
