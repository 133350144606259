<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-card flat>
      <v-card-text>
        <h2 class="mb-5 text-center">
          รายละเอียด ที่อยู่/จัดส่ง
        </h2>
        <hr class="mb-5 " />
        <v-row>
          <!-- ชื่อผู้จัดส่ง -->
          <v-col
            md="12"
            sm="6"
            cols="12"
            class="pt-3"
          >
            <p class="mb-0 pt-0">
              <v-text-field
                v-model="$store.state.orderDetil.sender_name"
                label="ชื่อผู้จัดส่ง"
                type="text"
                outlined
                dense
                hide-details
                placeholder="ชื่อผู้จัดส่ง"
                :rules="[v => !!v || 'กรุณากรอกชื่อผู้จัดส่ง']"
              >
              </v-text-field>
            </p>
          </v-col>
          <!-- ชื่อผู้จัดส่ง -->

          <!-- ที่อยู่ผู้จัดส่ง -->
          <v-col
            md="12"
            sm="6"
            cols="12"
            class="pt-1"
          >
            <p class="mb-0 pt-0">
              <v-textarea
                v-model="$store.state.orderDetil.sender_address"
                label="ที่อยู่จัดส่ง"
                hide-details
                auto-grow
                outlined
                rows="3"
                row-height="30"
                maxlength="255"
                :rules="[v => !!v || 'กรุณากรอกที่อยู่จัดส่ง']"
              >
              </v-textarea>
            </p>
          </v-col>
          <!-- ที่อยู่ผู้จัดส่ง -->
        </v-row>
        <hr class="mt-5 mb-5" />
        <v-row>
          <!-- ชื่อผู้รับ -->
          <v-col
            md="12"
            sm="6"
            cols="12"
            class="pt-3"
          >
            <p class="mb-0 pt-0">
              <v-text-field
                v-model="$store.state.orderDetil.recipient_name"
                label="ชื่อผู้รับ"
                type="text"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'กรุณากรอกชื่อผู้รับ']"
                placeholder="ชื่อผู้รับ"
              >
              </v-text-field>
            </p>
          </v-col>
          <!-- ชื่อผู้รับ -->

          <!-- ที่อยู่ผู้รับ -->
          <v-col
            md="12"
            sm="6"
            cols="12"
            class="pt-1"
          >
            <p class="mb-0 pt-0">
              <v-textarea
                v-model="$store.state.orderDetil.recipient_address"
                label="ที่อยู่ผู้รับ"
                hide-details
                auto-grow
                outlined
                :rules="[v => !!v || 'กรุณากรอกที่อยู่ผู้รับ']"
                rows="1"
                row-height="15"
                maxlength="200"
              >
              </v-textarea>
            </p>
          </v-col>
          <!-- ที่อยู่ผู้รับ -->

          <!-- แนบ ไฟล์ที่อยู่ผู้รับและผู้ส่ง -->
        </v-row>
        <hr class="mt-5 mb-5" />
        <v-row>
          <!-- รายละเอียดเพิ่มเติม -->
          <v-col
            md="12"
            sm="6"
            cols="12"
            class="pt-1"
          >
            <p class="mb-0 pt-0">
              <v-textarea
                v-model="$store.state.orderDetil.order_detail_oth"
                label="รายละเอียดเพิ่มเติม"
                hide-details
                auto-grow
                outlined
                rows="3"
                row-height="30"
                maxlength="255"
              >
              </v-textarea>
            </p>
          </v-col>
          <!-- แนบ ไฟล์ที่อยู่ผู้รับและผู้ส่ง -->
          <v-col
            md="12"
            sm="6"
            cols="12"
            class="pt-1"
          >
            <p class="mb-0 pt-0">
              <v-file-input
                v-model="$store.state.orderDetil.addressFile"
                show-size
                label="แนบไฟล์ที่อยู่ผู้รับและผู้ส่ง"
              >
              </v-file-input>
            </p>
            <p v-if="$store.state.orderDetil.address_member_file">
              <a
                :href="$store.state.orderDetil.address_member_file"
                target="_blank"
              >กดดูไฟล์ที่อยู่ผู้รับและผู้ส่ง</a>
            </p>
          </v-col>
          <!-- แนบ ไฟล์ที่อยู่ผู้รับและผู้ส่ง -->
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          v-if="($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id"
          :disabled="!$store.state.orderDetil.sender_name || !$store.state.orderDetil.sender_address || !$store.state.orderDetil.recipient_name || !$store.state.orderDetil.recipient_address "
          color="primary"
          @click="validateForm()"
        >
          บันทึกข้อมูล
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Swal from 'sweetalert2'
import { useVuelidate } from '@vuelidate/core'
import { mdiDelete, mdiPencil } from '@mdi/js'
import user from '../../../../services/user'
import config from '../../../../services/config'
import store from '@/store/index.js'
import orderAPI from '../../../../services/order'
import mainData from '@/services/mainData'

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  components: {
    Swal,
  },
  props: {
    getOrderDetail: {},
  },
  data() {
    return {
      valid: true,
      validate: false,
      configs: false,
      addressTpeList: ['ที่อยู่ใหม่', 'ที่อยู่ในระบบ'],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      selectBankking: store.state.selectBankking,
      customer: store.state.member,
      addressTpe: 'ที่อยู่ในระบบ',
      data: store.state.data,
      orderData: [],
      orderDetil: {},
      is_review2: false,
      setting: {},
    }
  },
  computed: {
    total() {
      const total = []
      Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
        if (val.price) {
          total.push(parseInt(parseInt(val.price) * parseInt(val.amount)))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num) + this.$store.state.orderDetil.transportTypePrice, 0)
    },
  },
  watch: {},
  updated() {
    // store.state.orderDetil = this.orderDetil
  },
  mounted() {
    this.$store.state.orderDetil.addressTpe = 'ที่อยู่ในระบบ'

    const vm = this
    const unwatch = this.$watch('$store.state.member', newQuestion => {
      vm.setMemberAddress()
      unwatch()
    })

    // this.showOrderMember()
    // this.$store.state.orderDetil.sender_name = ''
    // this.$store.state.orderDetil.sender_address = ''

    // let vm = this;
    // let unwatch = this.$watch('$store.state.orderDetil', (newQuestion) => {
    // vm.getSetting()
    // unwatch()
    // })
  },
  methods: {
    // getSetting() {
    //   mainData.showData().then(res => {
    //     this.setting = res.data.data;
    //     if (!this.$store.state.orderDetil.sender_name) {
    //       this.$store.state.orderDetil.sender_name = this.setting.main_data_name;
    //     }
    //     if (!this.$store.state.orderDetil.sender_address) {
    //       this.$store.state.orderDetil.sender_address = this.setting.main_data_address;
    //     }
    //   })
    // },
    validateForm() {
      this.updateOrderDetail()
    },
    setMemberAddress() {
      if (!this.$store.state.orderDetil.recipient_name || this.$store.state.orderDetil.recipient_name == '') {
        this.$store.state.orderDetil.recipient_name = this.$store.state.member.member_name
      }

      if (!this.$store.state.orderDetil.recipient_address || this.$store.state.orderDetil.recipient_address == '') {
        this.$store.state.orderDetil.recipient_address = this.$store.state.member.member_shop_address
      }
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    save() {
      this.formSubmit()
    },
    formSubmit() {
      const data = new FormData()
      data.append('orderData', JSON.stringify(this.$store.state.orderData))
      data.append('orderDetil', JSON.stringify(this.$store.state.orderDetil))
      orderAPI.createdOrder(data)
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    updateOrderDetail() {
      orderAPI
        .orderDetailDelivery(this.$store.state.orderDetil)
        .then(res => {
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'บันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
            this.$store.state.checkUpdateClicked = true

            // this.showOrderMember()
            this.getOrderDetail()
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}

img {
  height: 5.15rem;
  width: 4.235rem;
  border-radius: 50%;
  transform: translateX(-10px);
}

li {
  list-style-type: none;
}

hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
