<template>
  <v-stepper v-model="$store.state.step">
    <v-stepper-header>
      <v-stepper-step :complete="$store.state.step > 1" step="1">
        รายการสินค้า
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="$store.state.step > 2" step="2">
        การขนส่ง
      </v-stepper-step>

      <!--      <v-divider></v-divider>-->

      <!--      <v-stepper-step :complete="$store.state.step > 3" step="3">-->
      <!--        ชำระเงิน-->
      <!--      </v-stepper-step>-->

      <v-divider></v-divider>

      <v-stepper-step step="3">
        เสร็จสิ้น
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1" class="reset-padding-top">
        <v-card class="mb-12" color="grey">
          <!--          <StepOrder-->
          <!--            :getOrderDetail="getOrderDetail"-->
          <!--            :transportTypeItems="transportTypeItems"-->
          <!--            :brandList="brandList"-->
          <!--            :productTypes="productTypes"-->
          <!--            :Step="Step"/>-->

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <h2 v-if="$store.state.orderData && $store.state.orderData.length > 0"
                  class="mb-5 text-center">
                รายการสินค้า
              </h2>
              <!--      <v-alert v-if="$store.state.orderDetil && $store.state.orderDetil.order_inv" dense text-->
              <!--               type="warning">-->
              <!--        หมายเลขออเดอร์นี้ <strong> {{ $store.state.orderDetil.order_inv }} </strong> จะหมดอายุในเวลา-->
              <!--        : <strong>-->
              <!--        {{ $store.state.orderDetil.order_date_time }} </strong>-->
              <!--      </v-alert>-->
              <v-alert v-if="validate" type="error">
                {{ errorMessage }}
              </v-alert>


              <hr v-if="$store.state.orderData && $store.state.orderData.length > 0" class="mb-5"/>
              <div class="">
                <li v-for="(item, index) in $store.state.orderData" :key="item.id">
                  <v-row>
                    <v-col md="3" sm="12" cols="12" class="img_proauctRes">
                      <center>
                        <img
                          :src="item.pattern && item.pattern.location_files ? item.pattern.location_files : ''"
                          :alt="item.pattern && item.pattern.pattern_name ? item.pattern.pattern_name : ''"
                          onclick="window.open(this.src)"/>
                        <v-file-input v-model="item.changeFile"
                                      accept="image/png, image/jpeg, image/bmp"
                                      placeholder="แก้ไขไฟล์"
                                      @change="changeFile(index)"
                                      label="แก้ไขไฟล์"></v-file-input>
                        <!-- <p><a>แก้ไขไฟล์</a></p> -->
                      </center>
                    </v-col>
                    <v-col md="6" sm="12" cols="12">
                      <!-- กรุณาเลือกประเภทสินค้า -->
                      <v-select v-model="item.product_type_id" outlined dense hide-details
                                label="กรุณาเลือกประเภทสินค้า"
                                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"

                                :items="productTypes" item-text="product_type_name"
                                item-value="product_type_id" class="mt-2"
                                :rules="[v => !!v || 'กรุณาเลือกประเภทสินค้า']" required
                                @change="changeProuctType(index)"></v-select>
                      <!-- กรุณาเลือกประเภทสินค้า -->

                      <!-- กรุณาเลือกยี่ห้อ -->
                      <div v-if="item.product_type_id">
                        <v-select v-model="item.phone_brand_id" outlined
                                  :disabled="(!item.product_type_id) || !(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                                  dense
                                  hide-details label="กรุณาเลือกยี่ห้อ" :items="brandList"
                                  item-text="phone_brand_name"
                                  :rules="[v => !!v || 'กรุณาเลือกยี่ห้อ']" required
                                  item-value="phone_brand_id" class="mt-2"
                                  @change="changeModel(index)"></v-select>
                      </div>
                      <!-- กรุณาเลือกยี่ห้อ -->

                      <!-- สินค้าต้องการสั่งผลิต -->
                      <div v-if="item.phone_brand_id && item.product && item.product.product">
                        <v-select v-model="item.product_id"
                                  :disabled="(!item.phone_brand_id) || !(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                                  outlined dense
                                  hide-details label="สินค้าต้องการสั่งผลิต"
                                  :items="productList[index]"
                                  item-text="product_name"
                                  item-value="product_id"
                                  :rules="[v => !!v || 'กรุณาเลือกรุ่น']" required
                                  class="mt-2"
                                  @change="productPrice(index)">
                          <template v-slot:prepend-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-text-field v-model="searchTerm[index]" placeholder="Search"
                                              @input="searchProduct(index)"></v-text-field>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                      </div>
                      <!-- สินค้าต้องการสั่งผลิต -->

                    </v-col>
                    <v-col md="3" sm="12" cols="12" v-if="item.product_id">
                      <div v-if="item.phone_brand_id && item.product && item.product.product">
                        <!-- ราคา -->
                        <v-text-field v-model="item.product_price" label="ราคา" type="number"
                                      readonly
                                      :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                                      outlined dense
                                      hide-details placeholder="ราคา" class="mt-1"></v-text-field>
                        <!-- ราคา -->

                        <!-- จำนวนคงเหลือ -->
                        <div v-if="item.product">
                          <v-text-field v-model="item.product.remaining_online" label="จำนวนคงเหลือ"
                                        :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                                        readonly type="number"
                                        outlined dense hide-details placeholder="จำนวนคงเหลือ"
                                        class="mt-1"></v-text-field>
                        </div>
                        <!-- จำนวนคงเหลือ   -->

                        <div v-if="item.product">
                          <v-text-field v-model="item.product_amount" label="จำนวน" type="number"
                                        :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                                        :max="item.product.remaining_online" min="1" outlined dense
                                        hide-details placeholder="จำนวน"
                                        :rules="[v => !!v || 'กรุณาใสจำนวน']" required class="mt-1"
                                        @change="checkAmount(index)">
                          </v-text-field>
                        </div>

                        <!-- จำนวน -->
                        <div
                          v-if="(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                          class="d-flex align-center mt-2">

                          <v-icon style="color: red" class="me-1" @click="remove(index)">
                            {{ icons.mdiDelete }}
                          </v-icon>
                        </div>
                        <!-- จำนวน -->
                      </div>
                    </v-col>
                    <hr>
                  </v-row>
                </li>
              </div>

              <div class="mt-10"
                   v-if="(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)">
                <hr class="mt-5 mb-5"/>
                <div class="text-center wapperContentBottom">
                  <v-menu top :offset-y="offset">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="wapperIconContent">
                        <v-btn title="รูปอัพโหลดไฟล์เข้าระบบ" color="primary" v-bind="attrs"
                               hide-details
                               v-on="on">
                          <v-icon class="me-1" v-if="icons && icons.mdiPlus">
                            {{ icons.mdiPlus }}
                          </v-icon>
                          เพิ่มอัพโหลดไฟล์
                        </v-btn>
                      </div>
                    </template>
                    <v-list>
                      <v-list-item v-for="(item, index) in itemUploadProduct" :key="index">
                        <v-list-item-title>
                          <v-dialog transition="dialog-top-transition" width="1000">
                            <template v-slot:activator="{ on, attrs }">
                              <div>
                                <v-btn
                                  @click="uploadLink(index)"
                                  text v-bind="attrs" class="mt-5 testdialog"
                                       :disabled="item.disabled"
                                       v-on="on">
                                  <v-icon class="me-1">
                                    {{ item.icons }}
                                  </v-icon>
                                  {{ item.title }}
                                </v-btn>
                              </div>
                            </template>
                          </v-dialog>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item-title>
                        <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF"
                               class="uploadFile"
                               hide-details
                               multiple tabindex="-1" @change="uploadFile($event, 1)"/>
                      </v-list-item-title>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <v-row style="margin-top: 10px"  v-if="$store.state.orderData.length > 0">
                <v-col md="3" sm="3" cols="12" class="d-flex align-center">
                  <h3>วิธีการส่งสินค้า</h3>
                </v-col>
                <v-col md="3" sm="3" cols="12">
                  <v-select v-if="$store.state.orderDetil"
                            v-model="$store.state.orderDetil.transport_type_id" outlined dense
                            hide-details
                            label="กรุณาเลือกวิธีการส่งสินค้า" :items="transportTypeItems"
                            item-text="transport_type_name"
                            :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                            :rules="[v => !!v || 'กรุณาใสจำนวน']" required
                            item-value="transport_type_id"
                            class="mt-2"
                            @change="settransport()"></v-select>
                </v-col>
                <!-- วิธีการส่งสินค้า -->
                <v-col md="3" sm="3" cols="12" class="d-flex align-center">
                  <h3>ค่าจัดส่งสินค้า</h3>
                </v-col>
                <v-col md="3" sm="3" cols="12">
                  <v-text-field v-model="$store.state.orderDetil.transport_expense_price"
                                label="ค่าจัดส่ง(บาท)" readonly type="number"
                                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                                outlined dense hide-details placeholder="ค่าจัดส่ง"></v-text-field>
                </v-col>
                <v-col md="3" sm="3" cols="12" class="d-flex align-center">
                  <h3>จำนวนสินค้า</h3>
                </v-col>
                <v-col md="3" sm="3" cols="12">
                  <v-text-field v-model="amount" label="จำนวน(ชิ้น)" type="number" outlined readonly
                                dense
                                hide-details
                                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                                placeholder="จำนวน(ชิ้น)"></v-text-field>
                </v-col>
                <!-- ยอดสุทธิ -->
                <v-col md="3" sm="3" cols="12" class="d-flex align-center">
                  <h3>ยอดรวมสุทธิ</h3>
                </v-col>
                <v-col md="3" sm="3" cols="12">
                  <v-text-field v-model="total" label="ราคา (บาท)" type="number" outlined readonly
                                dense
                                hide-details
                                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                                placeholder="ราคา (บาท)"></v-text-field>
                </v-col>
              </v-row>

            </v-card-text>
<!--            <v-card-actions class="justify-end">-->
              <!--      <v-btn-->
              <!--        v-if="($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id"-->
              <!--        color="primary" @click="validateForm()">-->
              <!--        บันทึกข้อมูล-->
              <!--      </v-btn>-->
<!--            </v-card-actions>-->
          </v-form>
        </v-card>

        <v-card-actions class="justify-end">
          <!-- <v-btn

            @click="Step = 1"
          >
            ย้อนกลับ
          </v-btn> -->
          <!-- {{this.$store.state.validate}} -->
          <v-btn
            @click="formsubmit(2,true)"
            :disabled="(this.$store.state.validate) || (validateData(1))">
            ถัดไป
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
      <v-stepper-content step="2" class="reset-padding-top">
        <v-card class="mb-12" color="grey lighten-1">
          <StepDelivery
            :getOrderDetail="getOrderDetail"/>
        </v-card>

        <v-card-actions class="justify-end">
          <v-btn @click="formsubmit(1)">
            ย้อนกลับ
          </v-btn>
          <v-btn
            :disabled="validateData(2)"
            @click="formsubmit(3,true)">
            ถัดไป
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
      <!--      <v-stepper-content step="3" class="reset-padding-top">-->
      <!--        <v-card class="mb-12" color="grey lighten-1">-->
      <!--          <StepPayment-->
      <!--            :getOrderDetail="getOrderDetail"/>-->
      <!--        </v-card>-->
      <!--        <v-card-actions class="justify-end">-->
      <!--          &lt;!&ndash; <v-btn-->
      <!--            @click="Step = 3"-->
      <!--          >-->
      <!--            ย้อนกลับ-->
      <!--          </v-btn> &ndash;&gt;-->
      <!--          <v-btn @click="formsubmit(2)">-->
      <!--            ย้อนกลับ-->
      <!--          </v-btn>-->
      <!--          <v-btn-->
      <!--            :disabled="validateData(3)"-->
      <!--            @click="formsubmit(4,true)">-->
      <!--            ถัดไป-->
      <!--          </v-btn>-->
      <!--        </v-card-actions>-->
      <!--      </v-stepper-content>-->
      <v-stepper-content step="3" class="reset-padding-top">
        <v-card class="mb-12" color="grey lighten-1">
          <Success
            :transportTypeItems="transportTypeItems"
            :brandList="brandList"
            :productTypes="productTypes"
            :productList="productList"
            :getOrderDetail="getOrderDetail"/>
        </v-card>

        <v-card-actions class="justify-end">
          <v-btn
            v-if="(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
            @click="formsubmit(2)">
            ย้อนกลับ
          </v-btn>
          <v-btn
            color="error" @click="formsubmit(-1)">
            ปิด
          </v-btn>
          <v-btn color="primary"
                 v-if="(!$store.state.user.member_id)"
                 @click="openBill()">
            ดูบิล
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import StepOrder from '../step-transit/order/StepOrder.vue'
// import StepPayment from '../step-transit/order/StepPayment.vue'
import StepDelivery from '../step-transit/order/StepDelivery.vue'
import Success from '../step-transit/order/Success.vue'
import store from '@/store/index.js';
import instance_order from '@/services/order'
import productType from "@/services/productType";
import user from "@/services/user";
import getModelAPI from "@/services/model";
import transportTypeAPI from "@/services/transportType";
import Swal from "sweetalert2";
import orderAPI from "@/services/order";
import {required} from "@vuelidate/validators";
import {
  mdiCloseCircle,
  mdiCloudUpload, mdiDelete,
  mdiImageAlbum,
  mdiMinusCircle,
  mdiPlus,
  mdiPlusCircle
} from "@mdi/js";
import productAPI from "@/services/product";
import patternAPI from "@/services/pattern";
import orderDetailAPI from "@/services/orderDetail";
import productPriceAPI from "@/services/productPrice";


export default {
  components: {
    StepOrder,
    StepDelivery,
    Success,
  },
  updated() {
    this.orderDetil = store.state.orderDetil
    this.orderData = store.state.orderData
    this.validate = store.state.validate
  },
  validations() {
    var validate = {
      editedItem: {
        member_img_logo: {required},
        email: {required},
        password: {required},
        member_type_id: {required},
        member_shop_name: {required},
        member_name: {required},
        member_tele: {required},
      }
    };
    return validate
  },
  data() {
    return {
      Step: 1,
      customerLatest: [],
      brandList: [],
      productTypes: [],
      transportTypeItems: [],
      orderData: store.state.orderData,
      orderDetil: store.state.orderDetil,
      validate: store.state.validate,


      valid: true,
      // validate: false,
      errorMessage: '',
      attrs: '',
      product: [],
      itemUploadProduct: [
        {icons: mdiCloudUpload, title: 'อัพโหลดรูป', disabled: false},
        {icons: mdiCloudUpload, title: 'อัพโหลดลิงก์', disabled: false},
      ],
      offset: true,
      selectTransition: '',
      priceAdd: 0,
      discount: 0,
      price: 0,
      productSelect: [],
      transport_type_id: '',
      transportTypePrice: 0,
      bankList: '',
      productList: [],
      productListCopy: [],

      searchTerm: [],
      icons: {
        mdiPlus,
        mdiPlusCircle,
        mdiMinusCircle,
        mdiCloseCircle,
        mdiCloudUpload,
        mdiImageAlbum,
        // mdiArchiveRemove,
        mdiDelete,
      },
    }
  },
  computed: {

    amount() {
      const total = []
      Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
        if (val.product_amount) {
          total.push(parseInt(val.product_amount))
        } else {
          total.push(0)
        }
      })
      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    total() {
      const total = []
      Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
        if (val.product_price) {
          total.push(parseInt(parseInt(val.product_price) * parseInt(val.product_amount)))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
  },
  methods: {
    validateData(step) {
      var error = false;
      if (step == 1) {
        if (!this.$store.state.orderDetil.transport_type_id || (!this.$store.state.orderData || this.$store.state.orderData.length == 0)) {
          error = true;
        }

        for (var i = 0; i < this.$store.state.orderData.length; i++) {
          if (!this.$store.state.orderData[i].product_amount || this.$store.state.orderData[i].product_amount == 0) {
            error = true;
          }
        }
      } else if (step == 2) {
        if (!this.$store.state.orderDetil.sender_name) {
          error = true;
        }
        if (!this.$store.state.orderDetil.sender_address) {
          error = true;
        }
        if (!this.$store.state.orderDetil.recipient_name) {
          error = true;
        }
        if (!this.$store.state.orderDetil.recipient_address) {
          error = true;
        }
        // if (!this.$store.state.orderDetil.order_detail_oth){
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.addressFile){
        //   error = true;
        // }
      } else if (step == 3) {
        // if (!this.$store.state.orderDetil.PT_BankID) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.PT_date) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.PT_time) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.PT_price_amt) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.PT_slip && !this.$store.state.orderDetil.file_slip) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.order_detail_oth){
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.addressFile){
        //   error = true;
        // }
      }

      return error;
    },
    showOrderMember() {
      // this.orderData = [];
      let $this = this
      orderAPI
        .showOrder(store.state.member.member_id)
        .then(res => {
          if (res.status === 200) {
            this.$store.state.orderDetil = res.data
            if (res.data.order_detail) {
              this.$store.state.orderData = res.data.order_detail

              if (this.$store.state.orderData && this.$store.state.orderData.length > 0) {
                for (var i = 0; i < this.$store.state.orderData.length; i++) {
                  this.$store.state.orderData[i].changeFile = null
                }
              }
              // $this.orderDetil = res.data
              // this.$store.state.orderDetil = $this.orderDetil

              if (res.data.order_detail) {
                this.$store.state.orderData = res.data.order_detail
                // this.$store.commit('setOrderData', res.data.order_detail);
              }
            } else {
              this.$store.state.orderData = [];
            }


          }
          user.removrToken(res)
        })
        .catch(error => {

        })
    },
    getOrderDetail() {
      orderAPI
        .showOrderDetail(this.$store.state.orderDetil.order_id)
        .then(res => {
          if (res.status === 200) {
            if (res.data.order_detail) {
              // $this.orderDetil = res.data
              // this.$store.state.orderDetil = $this.orderDetil
              if (res.data.order_detail) {
                this.$store.state.orderData = res.data.order_detail
              }
              this.$store.state.orderDetil = res.data
            }

          }
          user.removrToken(res)
        })
        .catch(error => {

        })
    },

    getProductType() {
      const data = {
        search: this.search,
      }
      productType
        .apiProductType(data)
        .then(res => {

          if (res.status === 200) {
            this.productTypes = res.data
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(error => {

        })
    },
    getModel() {
      getModelAPI
        .getModel()
        .then(res => {

          if (res.status === 200) {
            this.brandList = res.data
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(error => {
        })
    },
    getTransportType() {
      transportTypeAPI
        .get()
        .then(res => {

          if (res.status === 200) {
            this.transportTypeItems = res.data
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {

          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })

        })
    },
    checkData(Step) {
      var data = {
        order_id: this.$store.state.orderDetil.order_id,
        step: Step,
      }
      this.$store.state.isLoading = true;
      instance_order.checkUpdateData(data).then(res => {
        this.$store.state.isLoading = false;
        if (res.data.success == false) {
          Swal.fire({
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          this.$store.state.step = Step
        }
      }).catch(err => {
        this.$store.state.isLoading = false;
        Swal.fire({
          icon: 'error',
          title: err.message,
          showConfirmButton: false,
          timer: 1500
        })
      });
    },
    openBill() {
      this.$router.push({ name : 'pages-order-detail' ,params :{ id : this.$store.state.orderDetil.order_id }})

    },
    formsubmit(Step, next = true) {
      if (Step == -1) {
        location.reload()
        // instance_order.orderUpdatePaid().then(res => {
        //
        // }).catch(err => {})

        // this.$emit('close')
      } else {
        if (Step == 1 && next) {
          this.$store.state.step = Step
        } else if (Step == 2 && next) {
          this.checkData(Step)
        } else if (Step == 3 && next) {
          this.checkData(Step)
        }
      }
    },
    getProductList() {
      if (this.$store.state.orderData && this.$store.state.orderData.length > 0) {
        var product_type_ids = ''
        var phone_brand_ids = ''
        var member_id = this.$store.state.member.member_type_id ?? this.$store.state.orderDetil.member;
        for (var i = 0; i < this.$store.state.orderData.length; i++) {
          if (i > 0) {
            phone_brand_ids += ',';
            product_type_ids += ',';
          }
          phone_brand_ids += (this.$store.state.orderData[i].phone_brand_id)
          product_type_ids += (this.$store.state.orderData[i].product_type_id)
          // phone_brand_ids.push(this.$store.state.orderData[i].phone_brand_id)
          // product_type_ids.push(this.$store.state.orderData[i].product_type_id)
        }


        productAPI
          .getProductsByType(product_type_ids, phone_brand_ids, member_id)
          .then(res => {
            if (res.status === 200) {
              if (res.data && res.data.length > 0) {
                this.productList = res.data
                this.productListCopy = res.data
              } else {
                this.validate = true
                this.errorMessage = 'ไม่พบสินค้า'
              }
            }
            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500
            })
          })
      }
    },
    changeFile(index) {
      let $this = this
      patternAPI
        .apiUpdatePattern(this.$store.state.orderData[index])
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })
        })
    },
    orderUpdate() {
      orderAPI
        .orderUpdate(this.$store.state.orderDetil)
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
            Swal.fire({
              icon: 'success',
              title: 'บันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500
            })
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          });
        })
    },
    validateForm() {
      this.$store.state.orderDetil.order_expense = this.total
      this.orderUpdate()
    },
    alertError(message) {
      alert(message)
    },
    settransport() {
      if (this.$store.state.orderDetil.transport_type_id) {
        transportTypeAPI
          .apiTransportExpensePriceRate(this.amount, this.$store.state.orderDetil.transport_type_id)
          .then(res => {
            if (res.status === 200) {
              this.$store.state.orderDetil.transport_expense_price = res.data
              this.validateForm()

            }
            // removrToken
            user.removrToken(res)
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    remove(index) {
      let $this = this
      orderDetailAPI
        .delete($this.$store.state.orderData[index].order_detail_id)
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })
        })
      // this.orderData.splice(index, 1)
    },
    // changeProduct(index, product_id) {
    //   this.orderData[index].productAmount = this.orderData[index].productAmoun
    //   productAmountAPI
    //     .getCheckAmount(product_id)
    //     .then(res => {
    //       if (res.status === 200) {
    //         this.orderData[index].productAmount = res.data
    //       }
    //       // removrToken
    //       user.removrToken(res)
    //     })
    //     .catch(error => {
    //       this.$swal({
    //         text: error.message,
    //         timer: 2000,
    //         icon: 'error',
    //         showConfirmButton: false,
    //         button: false
    //       })
    //     })
    // },
    changeProuctType(index) {
      this.$store.state.orderData[index].productSelect = []
      this.$store.state.orderData[index].phone_brand_id = ''
      this.$store.state.orderData[index].product_id = ''
    },
    changeModel(index) {
      this.$store.state.orderData[index].productSelect = []
      let phone_brand_id = this.$store.state.orderData[index].phone_brand_id
      let product_type_id = this.$store.state.orderData[index].product_type_id
      let $this = this
      let product = this.$store.state.orderData[index].product = {
        product: []
      }
      this.$store.state.orderData[index].product_id = null;
      productAPI
        .getProductByType(product_type_id, phone_brand_id, store.state.member)
        .then(res => {
          if (res.status === 200) {
            if (res.data && res.data.length > 0) {
              this.productList[index] = [];
              this.productList[index] = res.data
              this.productListCopy[index] = res.data
              this.productList.splice(index, 1, res.data)
              this.productListCopy.splice(index, 1, res.data)
            } else {
              this.validate = true
              this.errorMessage = 'ไม่พบสินค้า'
            }
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })
        })
    },
    uploadLink(index){
      if (index){

      }
    },
    uploadFile(event, type) {
      this.validateOrder();

      if (!this.validate && type === 1) {
        // const { orderData } = store.state
        // if(files[0]){
        let orderData = this.orderData
        let files = event.target.files
        let data = {
          order_id: this.$store.state.orderDetil.order_id ?? '',
          member_id: this.$store.state.member.member_id ?? this.$store.state.orderDetil.member_id,
          pattern_name: files[0].name,
          pattern_detail: '',
          file: files[0]
        }

        patternAPI
          .apiAddPattern(data)
          .then(res => {
            if (res.status === 200) {
              if (res.data.success) {

                Swal.fire({
                  icon: 'success',
                  title: "อัพโหลดสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500
                })
                this.getOrderDetail()
              } else {

                Swal.fire({
                  icon: 'error',
                  title: 'เกิดข้อผิดพลาด !!',
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            }
            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500
            })
          })
        // }


      }
    },
    searchProduct(index) {
      var vm = this
      const all = vm.productListCopy;
      if (!vm.searchTerm[index]) {
        vm.productList.splice(index, 1, all[index]);
      }

      let tmp = all[index].filter((product) => {
        return product.product_name.indexOf(vm.searchTerm[index]) > -1;
      });
      // vm.productList[index] = tmp;
      var abc = all;
      vm.productList.splice(index, 1, tmp)
      vm.productListCopy.splice(index, 1, abc)

    },
    changeProduct() {
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    checkAmount(index) {
      if (this.$store.state.orderData[index].product_amount) {
        if (this.$store.state.orderData[index].product_type_id && this.$store.state.orderData[index].product_id && this.$store.state.orderData[index].phone_brand_id) {
          if (this.$store.state.orderData[index].product_amount > this.$store.state.orderData[index].product.remaining_online) {
            this.validate = true
            this.errorMessage = 'จำนวนสินค้าไม่เพียงพอ'
            this.$store.state.orderData[index].product_amount = 0;
          } else {
            this.errorMessage = ''
            this.validate = false
          }
        }
        this.settransport();
        if (!this.validate) {
          //updateOrderDetail to save
          this.updateOrderDetail(index)
        }
      }


    },
    validateOrder() {
      for (let i = 0; i < this.$store.state.orderData.length; i++) {
        if (!this.$store.state.orderData[i].product_type_id && !this.$store.state.orderData[i].product_id || !this.$store.state.orderData[i].phone_brand_id) {
          this.validate = true
          this.errorMessage = 'กรุณากรอกข้อมูลให้ครบถ้วนก่อนเพิ่มสินค้า'
          break;
        }
      }

      for (let i = 0; i < this.$store.state.orderData.length; i++) {
        if (this.$store.state.orderData[i].product_amount > this.$store.state.orderData[i].product.remaining_online) {
          this.validate = true
          this.errorMessage = 'จำนวนสินค้าไม่เพียงพอ'
          break;
        }
      }
    },
    setProductPrice(index, product_id) {
      for (let i = 0; i < this.$store.state.orderData[index].product.product.length; i++) {
        if (this.$store.state.orderData[index].product.product[i].product_id === product_id) {
          this.$store.state.orderData[index].product_price = parseInt(this.$store.state.orderData[index].product.product[i].product_costs)
          // console.log(this.orderData[index])
          break;
        }
      }
    },


    checkProductDuplicate(index, product_id) {
      // let errorMessage = 'พบสินค้าซ้ำอยู่ในรายการ';
      // for (let i = 0; i < this.$store.state.orderData.length; i++) {
      //   if (this.$store.state.orderData[i].product_id === product_id) {
      //     this.validate = true
      //     this.errorMessage = errorMessage
      //     this.$store.state.orderData[index].product_id  = null
      //     this.alertError(errorMessage)
      //     break;
      //   }
      // }
    },

    productPrice(index) {
      let product_id = this.$store.state.orderData[index].product_id
      var member_type_id = this.$store.state.member.member_type_id ?? this.$store.state.orderDetil.member.member_type_id;
      // this.checkProductDuplicate(index, product_id)
      this.validate = false;
      if (!this.validate) {
        productPriceAPI
          .productPrice(product_id, member_type_id)
          .then(res => {
            if (res.status === 200) {
              if (res.data) {
                //check rate price
                this.$store.state.orderData[index].product_price = res.data
              } else {
                //check amount price
                this.$store.state.orderData[index].product_price = 0;
                this.setProductPrice(index, product_id)
              }
              if (!this.validate) {
                //updateOrderDetail to save
                this.updateOrderDetail(index)
              }
            }
            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500
            })
          })
      }

    },
    updateOrderDetail(index) {
      var order_expense = this.total
      orderDetailAPI
        .update(this.$store.state.orderData[index],order_expense)
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail();
          }
          // removrToken
          user.removrToken(res)
        })
        .catch(err => {

          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })

        })
    },
  },
  mounted() {
    this.getProductType()
    this.getModel()
    this.getTransportType()
    this.showOrderMember()
    let vm = this;
    let unwatch = this.$watch('$store.state.orderData', (newQuestion) => {
      vm.getProductList()
      unwatch()
    })

    // if (!this.$store.state.orderDetil.order_id) {
    //   console.log(222)
    //   this.showOrderMember()
    // }else{
    //   console.log(333)
    //   this.getOrderDetail()
    // }
  }
}
</script>

<style scoped>
.reset-padding-top {
  padding-top: 0 !important;
}


.v-input__slot {
  width: 50% !important;
}

.v-application--is-ltr .v-input__prepend-outer {
  margin-right: unset !important
}

.v-alert:not(.v-sheet--tile) {
  border-radius: 5px;
  margin-top: 10px;
}

a {
  text-decoration: none;
}

img {
  height: 5.15rem;
  width: 5.235rem;
  border-radius: 10px;
  /*margin-left: 30%;*/
}

li {
  list-style-type: none;
}

hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}

.testdialog {
  margin: 0 !important;
}

.wapper_content1 {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 23rem;
}

.wapperContentBottom {
  position: relative;
}

.wapperIconContent {
  transform: translateY(-100%);
  position: absolute;
  right: 36%;
}

.uploadFile {
  position: absolute;
  top: 15px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}

.img_proauctRes {


}


@media screen and (max-width: 600px) {
  .wapperIconContent {
    right: 25%;
  }
}
</style>
